@import '../../../scss/variables';

.services {
    padding: 0 1rem;
    
    .container {
        margin: 0 auto;
        max-width: 68ch;
    }

    h1 {
        margin-top: 6rem;
    }

    p {
        margin-top: 1rem;
    }

    .list {
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
    }

    .list-r {
        display: flex;
        align-items: center;

        p {
            margin-top: -0.05rem;
            padding-left: 1rem;
        }
    }

    i {
        color: $primary;
    }

    h2 {
        margin-top: 2rem;
        color: $dark;
    }
}

@media only screen and (min-width: 768px) {
    .services {
        h1 {
            text-align: center;
        }
    }
}