@import '../../../scss/variables';

.equipment {
    background-image: linear-gradient(
        rgba($dark, 0.2), 
        rgba($dark, 0.6) 15%
    ), url('../../../images/equip.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10rem 0;

    h1 {
        color: white;
        text-align: center;
    }

    h3 {
        color: white;
        text-align: center;
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 1200px) {
    .equipment {
        padding: 25rem 1rem;
    }
}