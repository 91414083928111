@import '../../scss/variables';

.compatable {
    padding: 4rem 1rem 4rem;
    background-color: $light;

    .alluminum {
        background-image: url('../../images/aluminum.jpg');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 17rem;
        margin-top: 1rem;
    }

    .copper {
        background-image: url('../../images/copper.jpg');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 17rem;
        margin-top: 2rem;
    }

    h1 {
        color: $dark;
    }

    ul {
        padding: 0;
        list-style-type: none;
        display: flex;

        li {
            display: flex;

            p {
                margin-left: 1rem;
                font-weight: 500;
            }
        }
    }

    i {
        margin-top: 0.3rem;
        color: $primary;
    }

    p {
        color: $gray;
    }

    .bottom {
        margin-top: 1rem;
    }

    span {
        font-weight: 900;
        color: $dark;
    }

    .other {
        margin-left: 1rem;
    }
}

@media only screen and (min-width: 768px) {
    .compatable {
        //background-color: white;

        h1 {
            text-align: center;
        }

        .container {
            margin: 0 auto;
        }

        .container-images {
            display: flex;
            justify-content: space-between;

            .copper, .alluminum {
                width: 48%;
            }

            .alluminum {
                margin-top: 2rem;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .compatable {
        h1 {
            text-align: start;
        }

        .body {
            font-size: 1.25rem;
            max-width: 66ch;
        }

        .container {
            display: flex;
            justify-content: center;
            max-width: 132ch;
        }

        p, ul {
            max-width: 50%;
        }

        .container-images {
            flex-direction: column;
            width: 25rem;

            .copper, .alluminum {
                width: 100%;
            }
        }
    }
}