@import '../../../scss/variables';

.about {
    padding: 0.5rem 0;
    margin-top: 6rem;

    .annah {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .christian {
        margin-top: 0.5rem;
    }

    i {
        color: $primary;
        //font-size: 1.5rem;
    }

    .container {
        //border: solid red 1px;
        padding: 0 1rem;
        margin: 0 auto;
    }

    .info {
        display: flex;
        justify-content: center;
    }

    h1 {
        text-align: center;
        color: $dark;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .p {
        padding-top: 1rem;
    }

    p {
        //padding: 1rem 0;
        //background-color: white;
    }

    img {
        float: right;
        margin-top: 0.6rem;
        margin-left: 0.2rem;
        padding-bottom: 1rem;
    }

    /*
    .content {
        display: flex;
        flex-wrap: wrap;

        .about {
            display: flex;
            //background-color: white;
            margin: 0;
        }

        .about-mad {
            flex-wrap: wrap;
        }

        .about1 {
            background-image: url('../../../images/about1.png');
            background-size: 80% 100%;
            background-repeat: no-repeat;
            width: 50%;
            height: 14rem;
        }

        .about2 {
            background-image: url('../../../images/about2.jpg');
            background-size: 80% 100%;
            background-repeat: no-repeat;
            width: 50%;
            height: 14rem;
            background-position: right;
        }

        .about3 {
            background-image: url('../../../images/about3.jpg');
            background-size: 80% 100%;
            background-repeat: no-repeat;
            width: 50%;
            height: 14rem;
        }

        .p2 {
            width: 50%;
        }
    } */
}

@media only screen and (min-width: 440px) {
    .about {
        img {
            margin-bottom: 0;
        }
    }
}

@media only screen and (min-width: 768px) {
    .about {
        .container {
            margin: 0 auto;
        }

        .info {
            /*
            display: flex;
            justify-content: center;
            */
        }

        .christian {
            margin-right: 1rem;
            margin-top: 1rem;
        }

        .annah {
            margin-left: 1rem;
        }

        .about2 {
            //border: solid red 1px;
            //background-position: right;
        }
    }
}