@import '../../scss/variables';
@import '../../scss/animations';

.service-title {
    text-align: center;
}

.service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .card {
        margin: 0 auto;
        display: flex;
        width: 100%;
        padding: 2rem 1rem;
        background-color: white;

        i {
            font-size: 2.5rem;
            color: $primary;
        }

        div {
            display: flex;
            flex-direction: column;
            padding-left: 2rem;
        }

        h5 {
            color: $dark;
        }

        a {
            text-decoration: none;
        }

        p {
            color: $gray;
        }
    }

    .other {
        background-color: $light;
    }
}

@media only screen and (min-width: 1200px) {
    .service {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        max-width: 150rem;
        margin: 0 auto;
        padding-bottom: 4rem;

        .title {
            font-size: 2.5rem;
            text-align: center;
            width: 100%;
            margin-top: 4rem;
            color: black;
        }

        .subtitle {
            width: 100%;
            text-align: center;
            color: $gray;
        }

        .card {
            margin: 2rem 1rem 2rem;
            display: flex;
            flex-direction: column;
            width: calc(20% - 0.5rem);
            padding: 1rem;
            //background-color: $light;

            &:hover {
                -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                //box-shadow: 0 0.5rem 6px -6px rgba($dark, 0.3);
                box-shadow: 0 8px 4px -4px rgba($dark, 0.5);
            }
    
            i {
                font-size: 4.5rem;
                color: $primary;
                //text-align: center;
            }
    
            div {
                display: flex;
                flex-direction: column;
                padding-left: 0rem;

                h5 {
                    //text-align: center;
                    font-weight: 500;
                    color: $dark;
                }
            }
    
            p {
                color: $gray;
                //text-align: center;
            }

            div {
                display: flex;
                flex-direction: column;

                h5 {
                    //height: 6rem;
                    color: $dark;
                    margin-top: 2rem;
                }

                i {
                    //height: 6rem;
                    margin-top: 2rem;
                }

                p {
                    margin-top: 1rem;
                    //border: solid red 1px;
                }
            }
        }
    
        .other {
            background-color: white;
        }
    }
}