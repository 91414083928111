@import './variables';

*, *::before, *::after {
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: $light;
    margin: 0;
    min-width: 320px;
}

h1, h2, h3, h4, h5, p {
    margin: 0;
}

h1 {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.1;
    color: $dark;
}

h2 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
}

h3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.3;
}

h4 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
}

h5 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: $dark;
}

p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
}

.container {
    font-size: 1rem;
    max-width: 66ch;
    margin: 0;
}

@media only screen and (min-width: 1200px) {
    h1 {
        font-size: 5rem;
    }
    
    h2 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.2;
    }
    
    h3 {
        font-size: 3rem;
        line-height: 1.3;
    }
    
    h4 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    h5 {
        font-size: 2rem;
        //font-weight: 400;
    }
    
    p {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.7;
    }
    
    .container {
        font-size: 1.25rem;
        max-width: 66ch;
        margin: 0;
    }
}

