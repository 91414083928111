@import '../../scss/variables';

.briquetters {
    padding: 4rem 1rem;
    background-color: white;

    h2 {
        color: $dark;
    }

    p {
        margin-top: 0.5rem;
    }

    .card {
        margin-top: 1rem;
        background-size: 100% 100%;
        height: 20rem;

        .image {
            height: 20rem;
            //position: relative;

            p {
                position: absolute;
                background-color: rgba($dark, 0.9);
                padding: 0.5rem;
                color: white;
                width: 100%;
                bottom: 0rem;
            }
        }

        .description {
            background-color: $light;
            height: 23rem;
            margin-top: -0.5rem;
            padding: 0 0.5rem;
            text-align: start;
        }
    }

    .title {
        background-color: $dark;
        color: white;
        text-align: center;
        margin-top: 0;
        height: 3rem;
        line-height: 3rem;
    }

    .conveyors {
        margin-top: 4rem;
    }

    .card-1, .image-1 {
        background-image: url('../../images/ARS1000.jpg');     
    }

    .card-2, .image-2 {
        background-image: url('../../images/ARS3000.jpg');     
    }

    .card-3, .image-3 {
        background-image: url('../../images/ARS600.jpg');  
        height: 30rem;

        .image {
            p {
                bottom: -10rem;
            }
        }

        .description {
            height: 33rem;
        }   
    }

    .card-4, .image-4 {
        background-image: url('../../images/PMC225.png');     
    }

    .card-5, .image-5 {
        background-image: url('../../images/PMC275.jpg');  
    }

    .card-6, .image-6 {
        background-image: url('../../images/RUF1100.jpg');     
    }

    .card-7, .image-7 {
        background-image: url('../../images/RUF15.jpg');     
    }

    .card-8, .image-8 {
        background-image: url('../../images/600.jpg');     
    }

    .card-9, .image-9 {
        background-image: url('../../images/SHREDDER.png');
        height: 30rem;

        .image {
            p {
                bottom: -10rem;
            }
        }

        .description {
            height: 33rem;
        }      
    }
}

@media only screen and (min-width: 768px) {
    .briquetters {
        margin: 0 auto;
        margin-bottom: 20rem;
        background-color: $light;
        max-width: 90rem;

        .card {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            height: 35rem;
            background-color: white;
            padding: 1rem;

            div {
                width: calc(50% - 1rem);
                max-width: calc(50% - 1rem);
            }

            .image {
                background-size: 100% 100%;
                height: 33rem;
            }
        }    

        .card {
            background-image: none;
            //box-shadow: 0px 0px 1px 1px rgba($dark, 0.5);     
        }

        .conveyors {
            margin-top: 8rem;
        }
    }
}