@import '../../scss/variables';

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    background-color: rgba($dark, 0.9);
    height: 4rem;
    width: 100%;

    button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        outline: none;
        font-size: 2rem;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;

        hr {
            width: 2.25rem;
            border: solid white 1px;
            border-radius: 1rem;
            margin: 0.4rem 0;
        }

        .hr-2 {
            width: 1.6875rem;
        }

        .hr-3 {
            width: 1.125rem;
        }
    }
}

.side-drawer {
    position: fixed;
    width: 100%;
    top: 0;
    top: -100%;
    background-color: rgba($dark, 0.9);
    transition: 850ms;
    z-index: 999;
    padding: 0 1rem;

    h4 {
        font-weight: 400;
        margin-bottom: 2rem;
        text-align: center;
    }

    .link {
        color: white;
        text-decoration: none;
    }
}

.side-drawer-active {
    top: 4rem;
    transition: 350ms;
}

@media only screen and (min-width: 1200px) {
    .header {
        height: 5rem;
        align-items: center;
        justify-content: center;

        .link {
            color: white;
            text-decoration: none;
            line-height: 4rem;
            margin: 0 3rem;
        }
    }
}