@import '../../scss/variables';

.advantage {
    padding: 4rem 1rem 4rem;
    background-color: white;

    .green {
        background-image: url('../../images/green.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 15rem;
        margin-top: 2rem;
    }

    h1 {
        color: $dark;
        margin-top: 1rem;
        //border: solid red 1px;
    }

    ul {
        padding: 0;
        list-style-type: none;
        //border: solid red 1px;

        li {
            display: flex;
        }
    }

    i {
        margin-top: 0.3rem;
        color: $primary;
    }

    p {
        margin-left: 1rem;
        color: $gray;
    }
}

@media only screen and (min-width: 768px) {
    .advantage {
        h1 {
            text-align: center;
        }

        .container-list {
            display: flex;
            font-size: 1rem;
            max-width: 66ch;
            margin: 0 auto;
            justify-content: space-between;
        }

        .green {
            background-image: url('../../images/green.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 15rem;
            margin-top: 0rem;
            width: 40%;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .advantage {
        border-bottom: solid 1px white;

        h1 {
            text-align: start;
        }

        .container-list {
            font-size: 1.25rem;
            max-width: 80ch;
        }

        .green {
            height: 25rem;
            margin-top: 1rem;
            font-size: 1.25rem;
            width: 66ch;
        }

        .container-list {
            font-size: 1.5rem;
        }

        p {
            font-size: 1.5rem;
        }
    }
}