@import '../../scss/variables';

.conditioning {
    padding: 4rem 1rem 1rem;
    background-color: $light;

    h1 {
        color: $dark;
    }

    p {
        color: $gray;
        margin-top: 1rem;
    }

    h2 {
        margin-top: 2rem;
    }

    ul {
        padding: 0;
        list-style-type: none;

        li {
            display: flex;

            p {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }

    i {
        margin-top: 0.3rem;
        color: $primary;
    }

    .rats {
        background-image: url('../../images/rats.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 17rem;
        margin-top: 1rem;
    }

    .rats2 {
        background-image: url('../../images/rats2.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        height: 17rem;
        margin-top: 1rem;
    }
}

@media only screen and (min-width: 768px) {
    .conditioning {

        h1 {
            text-align: center;
        }

        .container { 
            margin: 0 auto;
        }

        .container-images {
            display: flex;
            justify-content: space-between;

            .rats, .rats2 {
                width: 48%;
            }
        }
    }
}