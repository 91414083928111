@import '../../scss/variables';
@import '../../scss//animations';

.intro {
    background-image: linear-gradient(
        rgba($dark, 0.2), 
        rgba($dark, 0.6) 15%
    ), url('../../images/briquettes.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10rem 1rem 2rem;
    text-align: center;

    .logoLeaf {
        background-image: url('../../images/leaf.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 7rem;
        margin-bottom: 1.5rem;
    }

    .logoText {
        background-image: url('../../images/textLogo.png');
        background-size: contain;
        height: 5rem;
        //width: auto;
        background-position: center;
        background-repeat: no-repeat;
        padding-left: 1px;
    }

    /*
    h1 {
        color: white;
        letter-spacing: 0.2rem;
    }
    */

    h3 {
        color: white;
        //margin-top: 2rem;
        //font-weight: 400;
    }

    .rentals {
        //font-weight: 400;
        margin-top: 2rem;
    }

    button {
        margin-top: 2rem;
        border: none;
        outline: none;
        background-color: $primary;
        color: white !important;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        box-shadow: 0 8px 4px -4px rgba($dark, 1);
        font-weight: 300;
        font-size: 1.5rem;

        &:hover {
            -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .intro {
        padding-bottom: 10rem;

        .logoLeaf {
            height: 14rem;
            margin-bottom: 3rem;
        }

        .logoText {
            height: 10rem;
        }

        .rentals {
            margin-top: 4rem;
        }

        button {
            margin-top: 4rem;
            padding: 0;
            width: 17rem;
            height: 4rem;
            line-height: 4rem;
            box-shadow: 0 8px 4px -4px rgba($dark, 1);
            font-weight: 400;
            font-size: 2.75rem;
        }
    }
}